<template>
  <div class="fluid" id="requestList">
    <v-card
      tile
      class="d-flex justify-center align-center flex-column"
      elevation="2"
      style=" padding:0; width:100%;height:10%;background:hsla(60,70%,60%,0.5)"
    >
      <p
        class="indigo--text"
        style="
              margin: 0;
              font-size: 22px;
              font-weight: bold;
              text-align: center;
              color: rgba(0, 0, 0);
            "
      >
        ASSET MANAGEMENT SYSTEM
      </p>
      <p
        class="indigo--text"
        style="
              margin: 0;
              font-size: 16px;
              font-weight: bold;
              text-align: center;
              color: rgba(0, 0, 0);
            "
      >
        DASHBOARD
      </p>
    </v-card>
    <div style="height:90%;position:relative;">
      <v-row no-gutters style="height:100%;">
        <v-col
          cols="12"
          md="12"
          style="border:1.2px solid rgba(0,0,0,0.2);padding:10px;"
        >
          <!-- height="35vh" -->
          <v-data-table
            mobile-breakpoint="0"
            :items="result"
            :headers="headers"
            fixed-header
            class="elevation-2 mx-auto"
            style="width:99%; margin:20px 0;cursor: pointer;"
            :loading="loading"
            :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
            }"
            @click:row="rowClick"
          >
            <template v-slot:top>
              <div
                style="border-radius:10px 10px 0 0; border-bottom:1px solid #e0e0e0;padding:0 10px;"
              >
                <v-row no-gutters>
                  <v-col cols="6">
                    <v-card
                      text
                      rounded
                      elevation="0"
                      class="indigo--text font-weight-bold ma-2"
                      style="font-size:18px;"
                    >
                      Notifikasi Pemindahan Asset
                      <v-tooltip bottom color="grey">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            x-small
                            text
                            icon
                            color="grey darken-2"
                            @click="initDataTable"
                          >
                            <v-icon small>mdi-refresh</v-icon>
                          </v-btn>
                        </template>
                        <span>refresh page</span>
                      </v-tooltip>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </template>
            <template v-slot:[`item.schedule_from`]="{ item }">
              <div style="padding: 0; display: flex; flex-direction: row">
                {{ convertDate(item.schedule_from) }}
              </div>
            </template>
            <template v-slot:[`item.schedule_to`]="{ item }">
              <td class="arch-expDate">
                <div class="arch-exp-date">
                  <div style="width: 120px; padding: 0 5px">
                    <p style="margin: 0">
                      {{ convertDate(item.schedule_to) }}
                    </p>
                  </div>

                  <div
                    class="arch-exp-date-notif"
                    v-if="countdown2(item.schedule_to) <= '7'"
                  >
                    <div class="exp-date-notif-div">
                      <v-chip
                        class="exp-date-notif-chip1"
                        v-if="countdown2(item.schedule_to) > '0'"
                        small
                        color="orange"
                        text-color="white"
                      >
                        Done in
                        {{ countdown2(item.schedule_to) }} days
                      </v-chip>
                      <v-chip
                        class="exp-date-notif-chip2"
                        v-else-if="countdown2(item.schedule_to) < '0'"
                        small
                        color="red"
                        text-color="white"
                      >
                        <p class="date-notif-chip2-p">
                          exceeded
                        </p>
                      </v-chip>
                      <v-chip
                        class="exp-date-notif-chip2"
                        v-else
                        small
                        color="pink"
                        text-color="white"
                      >
                        <p class="date-notif-chip2-p">Done</p>
                      </v-chip>
                    </div>
                  </div>
                </div>
              </td>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <div
                :style="
                  `padding: 0; display: flex; flex-direction: row; color:${color(
                    item.status
                  )};`
                "
              >
                {{ movementStatus(item.status) }}
              </div>
            </template>
          </v-data-table>
        </v-col>
        <!-- <v-col cols="12" md="6"> </v-col> -->
      </v-row>
    </div>
    <div style="position:fixed; bottom:10px; right:0;">
      <Scanner />
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../../services/buildType'
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  components: {
    Scanner: () => import('../../home/components/Scanner.vue')
  },
  data: () => ({
    address: '',
    // eSanqua: buildType.apiURL('esanqua'),
    asset: buildType.apiURL('asset'),
    build: process.env.VUE_APP_BUILD_TYPE,
    isAdmin: false,
    menu_key: 41,
    application: {
      id: 18,
      name: 'e-Asset Management System'
    },
    permission: {},
    result: [],
    loading: false,
    detail: null,

    tabs: [
      { tab_name: 'Dashboard', path: '/asset-management' },
      {
        tab_name: 'Aset & Kepemilikan',
        path: '/asset-management/aset-kepemilikan'
      },
      {
        tab_name: 'Pemindahan Aset',
        path: '/asset-management/pemindahan-aset'
      },
      {
        tab_name: 'Kategori',
        path: '/asset-management/kategori'
      }
      // {
      //   tab_name: 'Terjual',
      //   path: '/asset-management/terjual'
      // }
      // {
      //   tab_name: 'Form Pemindahan',
      //   path: '/asset-management/form-pemindahan'
      // },
      // {
      //   tab_name: 'Penerimaan Aset',
      //   path: '/asset-management/penerimaan-aset'
      // },
      // { tab_name: 'Stock Report', path: '/asset-management/stock-report' }
    ],
    paramAPI: {
      loading: false,
      page: 1,
      itemsPerPage: 10,
      offset: 0,
      limit: 1000,
      totalItems: 0,
      keyword: '',
      sortBy: '',
      sortType: ''
    },
    headers: [
      {
        text: 'No. Pemindahan',
        value: 'doc_no',
        align: 'left',
        sortable: false
      },
      {
        text: 'PIC',
        value: 'created_by_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Department',
        value: 'source_department_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Plant',
        value: 'source_company_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Pemindahan',
        value: 'schedule_from',
        align: 'left',
        sortable: false
      },
      {
        text: 'Destinasi',
        value: 'destination_company_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Pengembalian',
        value: 'schedule_to',
        align: 'left',
        sortable: false
      },
      {
        text: 'Status',
        value: 'status',
        align: 'left',
        sortable: false
      }
    ]
  }),
  created() {
    var arr = this.getUserProfile.level
    this.setTabs(this.tabs)
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then(result => {
            this.permission = result.data
            this.setPermission(result.data)
            if (arr[i].id === '1') {
              this.isAdmin = true
            } else {
              this.isAdmin = false
            }
            this.initDataTable()
          })
          .catch(error => {
            if (error) {
              this.isAdmin = false
              this.permission = {
                read_perm: 0
              }
              this.setPermission({ read_perm: 0 })
            }
          })
      } else {
        this.isAdmin = false
        this.permission = {
          read_perm: 0
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  methods: {
    ...mapActions(['menu_access']),
    ...mapMutations(['setPermission', 'setTabs', 'setParamAsset']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    clear() {
      this.detail = null
    },
    initDataTable() {
      this.result = []
      axios
        .get(
          `${this.asset}movement/list?keyword=${this.paramAPI.keyword}&order_by=${this.paramAPI.sortBy}&order_type=${this.paramAPI.sortType}&offset=${this.paramAPI.offset}&limit=${this.paramAPI.limit}`
        )
        .then(res => {
          const response = res.data.data
          console.log(response)
          if (response !== undefined) {
            for (let i = 0; i < response.length; i++) {
              if (this.countdown(response[i].schedule_to) <= 7) {
                this.result.push({
                  createdAt: response[i].createdAt,
                  created_by_name: response[i].created_by_name,
                  destination_company_name:
                    response[i].destination_company_name,
                  doc_no: response[i].doc_no,
                  id: response[i].id,
                  schedule_from: response[i].schedule_from,
                  schedule_to: response[i].schedule_to,
                  source_company_name: response[i].source_company_name,
                  source_department_name: response[i].source_department_name,
                  status: response[i].status
                })
              }
            }
          } else {
            this.result = []
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString('id')
        return local
      }
    },
    // convertDate(raw) {
    //   if (raw !== null) {
    //     return raw.replace(/-/g, '/')
    //   }
    // },
    countdown(raw) {
      if (raw !== null) {
        console.log(raw, raw.replace(/-/g, '/'))
        const z = new Date(raw.replace(/-/g, '/'))

        const a = new Date()
        const n = a.toLocaleDateString('id')
        const month = n.slice(0, n.indexOf('/'))
        const day = n.slice(n.indexOf('/') + 1, n.lastIndexOf('/'))
        const year = n.slice(n.lastIndexOf('/') + 1)
        let x = ''
        // if (navigator.userAgent.includes('Chrome')) {
        //   x = new Date(year2 + '/' + month2 + '/' + day2)
        // } else {
        x = new Date(year + '/' + day + '/' + month)
        // }
        const diffInTime = z.getTime() - x.getTime()
        const q = diffInTime / (1000 * 3600 * 24)
        return q
      }
    },
    countdown2(raw) {
      if (raw !== null) {
        const z = new Date(raw.replace(/-/g, '/'))

        const a = new Date()
        const n = a.toLocaleDateString('id')
        const month = n.slice(0, n.indexOf('/'))
        const day = n.slice(n.indexOf('/') + 1, n.lastIndexOf('/'))
        const year = n.slice(n.lastIndexOf('/') + 1)
        let x = ''
        // if (navigator.userAgent.includes('Chrome')) {
        //   x = new Date(year2 + '/' + month2 + '/' + day2)
        // } else {
        x = new Date(year + '/' + day + '/' + month)
        // }
        const diffInTime = z.getTime() - x.getTime()
        const q = diffInTime / (1000 * 3600 * 24)
        return q
      }
    },
    movementStatus(v) {
      switch (v) {
        case -2:
          return 'Canceled'

        case 1:
          return 'Pending'

        case 2:
          return 'Approved'

        default:
          return 'null'
      }
    },
    color(v) {
      switch (v) {
        case -2:
          return 'red'

        case 1:
          return 'grey'

        case 2:
          return 'green'

        default:
          return 'black'
      }
    },

    rowClick(pItem) {
      setTimeout(() => {
        this.$router.push(`/asset-management/detail-penerimaan/${pItem.id}`)
      }, 300)
    }
  }
}
</script>
<style lang="scss" src="">
#requestList {
  position: relative;
  height: 91vh;
  background: white;
}
.arch-expDate {
  cursor: pointer;
  height: 40px;
  .arch-exp-date {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    .arch-exp-date-notif {
      overflow: hidden;
      width: 120px;
      position: absolute;
      left: 0;
      .exp-date-notif-div {
        display: flex;

        .exp-date-notif-chip1 {
          width: 95px;
          font-size: 10px;
        }
        .exp-date-notif-chip2 {
          font-size: 10px;
          .date-notif-chip2-p {
            width: 70px;
            margin: 0;
            margin: auto;
            text-align: center;
            // padding: 0 10px !important;
          }
        }
      }
    }
  }

  &:hover .arch-exp-date-notif {
    width: 0;
    transition: 0.5s;
  }
}
</style>
