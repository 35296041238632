var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fluid",attrs:{"id":"requestList"}},[_c('v-card',{staticClass:"d-flex justify-center align-center flex-column",staticStyle:{"padding":"0","width":"100%","height":"10%","background":"hsla(60,70%,60%,0.5)"},attrs:{"tile":"","elevation":"2"}},[_c('p',{staticClass:"indigo--text",staticStyle:{"margin":"0","font-size":"22px","font-weight":"bold","text-align":"center","color":"rgba(0, 0, 0)"}},[_vm._v(" ASSET MANAGEMENT SYSTEM ")]),_c('p',{staticClass:"indigo--text",staticStyle:{"margin":"0","font-size":"16px","font-weight":"bold","text-align":"center","color":"rgba(0, 0, 0)"}},[_vm._v(" DASHBOARD ")])]),_c('div',{staticStyle:{"height":"90%","position":"relative"}},[_c('v-row',{staticStyle:{"height":"100%"},attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"border":"1.2px solid rgba(0,0,0,0.2)","padding":"10px"},attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"elevation-2 mx-auto",staticStyle:{"width":"99%","margin":"20px 0","cursor":"pointer"},attrs:{"mobile-breakpoint":"0","items":_vm.result,"headers":_vm.headers,"fixed-header":"","loading":_vm.loading,"footer-props":{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
          }},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticStyle:{"border-radius":"10px 10px 0 0","border-bottom":"1px solid #e0e0e0","padding":"0 10px"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"indigo--text font-weight-bold ma-2",staticStyle:{"font-size":"18px"},attrs:{"text":"","rounded":"","elevation":"0"}},[_vm._v(" Notifikasi Pemindahan Asset "),_c('v-tooltip',{attrs:{"bottom":"","color":"grey"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","text":"","icon":"","color":"grey darken-2"},on:{"click":_vm.initDataTable}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("refresh page")])])],1)],1)],1)],1)]},proxy:true},{key:"item.schedule_from",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"padding":"0","display":"flex","flex-direction":"row"}},[_vm._v(" "+_vm._s(_vm.convertDate(item.schedule_from))+" ")])]}},{key:"item.schedule_to",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"arch-expDate"},[_c('div',{staticClass:"arch-exp-date"},[_c('div',{staticStyle:{"width":"120px","padding":"0 5px"}},[_c('p',{staticStyle:{"margin":"0"}},[_vm._v(" "+_vm._s(_vm.convertDate(item.schedule_to))+" ")])]),(_vm.countdown2(item.schedule_to) <= '7')?_c('div',{staticClass:"arch-exp-date-notif"},[_c('div',{staticClass:"exp-date-notif-div"},[(_vm.countdown2(item.schedule_to) > '0')?_c('v-chip',{staticClass:"exp-date-notif-chip1",attrs:{"small":"","color":"orange","text-color":"white"}},[_vm._v(" Done in "+_vm._s(_vm.countdown2(item.schedule_to))+" days ")]):(_vm.countdown2(item.schedule_to) < '0')?_c('v-chip',{staticClass:"exp-date-notif-chip2",attrs:{"small":"","color":"red","text-color":"white"}},[_c('p',{staticClass:"date-notif-chip2-p"},[_vm._v(" exceeded ")])]):_c('v-chip',{staticClass:"exp-date-notif-chip2",attrs:{"small":"","color":"pink","text-color":"white"}},[_c('p',{staticClass:"date-notif-chip2-p"},[_vm._v("Done")])])],1)]):_vm._e()])])]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('div',{style:(("padding: 0; display: flex; flex-direction: row; color:" + (_vm.color(
                  item.status
                )) + ";"))},[_vm._v(" "+_vm._s(_vm.movementStatus(item.status))+" ")])]}}],null,true)})],1)],1)],1),_c('div',{staticStyle:{"position":"fixed","bottom":"10px","right":"0"}},[_c('Scanner')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }